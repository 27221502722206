@font-face {
  font-family: 'Flaticon';
  src: url('fonts/Flaticon.eot?mpa9us');
  src: url('fonts/Flaticon.eot?mpa9us#iefix') format('embedded-opentype'),
    url('fonts/Flaticon.ttf?mpa9us') format('truetype'),
    url('fonts/Flaticon.woff?mpa9us') format('woff'),
    url('fonts/Flaticon.svg?mpa9us#Flaticon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='flaticon-'],
[class*=' flaticon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Flaticon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-grapes .path1:before {
  content: '\e900';
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path2:before {
  content: '\e901';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path3:before {
  content: '\e902';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path4:before {
  content: '\e903';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path5:before {
  content: '\e904';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path6:before {
  content: '\e905';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path7:before {
  content: '\e906';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-grapes .path8:before {
  content: '\e907';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path9:before {
  content: '\e908';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path10:before {
  content: '\e909';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path11:before {
  content: '\e90a';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-grapes .path12:before {
  content: '\e90b';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-grapes .path13:before {
  content: '\e90c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path14:before {
  content: '\e90d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path15:before {
  content: '\e90e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path16:before {
  content: '\e90f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path17:before {
  content: '\e910';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path18:before {
  content: '\e911';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path19:before {
  content: '\e912';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path20:before {
  content: '\e913';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path21:before {
  content: '\e914';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path22:before {
  content: '\e915';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path23:before {
  content: '\e916';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path24:before {
  content: '\e917';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path25:before {
  content: '\e918';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path26:before {
  content: '\e919';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path27:before {
  content: '\e91a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path28:before {
  content: '\e91b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path29:before {
  content: '\e91c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path30:before {
  content: '\e91d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path31:before {
  content: '\e91e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-grapes .path32:before {
  content: '\e91f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-melon .path1:before {
  content: '\e920';
  color: rgb(177, 204, 52);
}
.flaticon-melon .path2:before {
  content: '\e921';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-melon .path3:before {
  content: '\e922';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-melon .path4:before {
  content: '\e923';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-melon .path5:before {
  content: '\e924';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-melon .path6:before {
  content: '\e925';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-melon .path7:before {
  content: '\e926';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-melon .path8:before {
  content: '\e927';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path1:before {
  content: '\e928';
  color: rgb(177, 204, 52);
}
.flaticon-watermelon .path2:before {
  content: '\e929';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-watermelon .path3:before {
  content: '\e92a';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-watermelon .path4:before {
  content: '\e92b';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-watermelon .path5:before {
  content: '\e92c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path6:before {
  content: '\e92d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path7:before {
  content: '\e92e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path8:before {
  content: '\e92f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path9:before {
  content: '\e930';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path10:before {
  content: '\e931';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path11:before {
  content: '\e932';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path12:before {
  content: '\e933';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path13:before {
  content: '\e934';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-watermelon .path14:before {
  content: '\e935';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path1:before {
  content: '\e936';
  color: rgb(240, 179, 27);
}
.flaticon-tangerine .path2:before {
  content: '\e937';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-tangerine .path3:before {
  content: '\e938';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-tangerine .path4:before {
  content: '\e939';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-tangerine .path5:before {
  content: '\e93a';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-tangerine .path6:before {
  content: '\e93b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path7:before {
  content: '\e93c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path8:before {
  content: '\e93d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path9:before {
  content: '\e93e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path10:before {
  content: '\e93f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path11:before {
  content: '\e940';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tangerine .path12:before {
  content: '\e941';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lemon .path1:before {
  content: '\e942';
  color: rgb(252, 234, 45);
}
.flaticon-lemon .path2:before {
  content: '\e943';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-lemon .path3:before {
  content: '\e944';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-lemon .path4:before {
  content: '\e945';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-lemon .path5:before {
  content: '\e946';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-lemon .path6:before {
  content: '\e947';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-banana .path1:before {
  content: '\e948';
  color: rgb(252, 234, 45);
}
.flaticon-banana .path2:before {
  content: '\e949';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-banana .path3:before {
  content: '\e94a';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-banana .path4:before {
  content: '\e94b';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-banana .path5:before {
  content: '\e94c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-banana .path6:before {
  content: '\e94d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-banana .path7:before {
  content: '\e94e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path1:before {
  content: '\e94f';
  color: rgb(165, 121, 56);
}
.flaticon-pineapple .path2:before {
  content: '\e950';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-pineapple .path3:before {
  content: '\e951';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-pineapple .path4:before {
  content: '\e952';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-pineapple .path5:before {
  content: '\e953';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pineapple .path6:before {
  content: '\e954';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pineapple .path7:before {
  content: '\e955';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pineapple .path8:before {
  content: '\e956';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pineapple .path9:before {
  content: '\e957';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pineapple .path10:before {
  content: '\e958';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path11:before {
  content: '\e959';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path12:before {
  content: '\e95a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path13:before {
  content: '\e95b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path14:before {
  content: '\e95c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pineapple .path15:before {
  content: '\e95d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mango .path1:before {
  content: '\e95e';
  color: rgb(252, 234, 45);
}
.flaticon-mango .path2:before {
  content: '\e95f';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-mango .path3:before {
  content: '\e960';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-mango .path4:before {
  content: '\e961';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-mango .path5:before {
  content: '\e962';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-mango .path6:before {
  content: '\e963';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mango .path7:before {
  content: '\e964';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mango .path8:before {
  content: '\e965';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-red_apple .path1:before {
  content: '\e966';
  color: rgb(234, 90, 71);
}
.flaticon-red_apple .path2:before {
  content: '\e967';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-red_apple .path3:before {
  content: '\e968';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-red_apple .path4:before {
  content: '\e969';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-red_apple .path5:before {
  content: '\e96a';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-red_apple .path6:before {
  content: '\e96b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-red_apple .path7:before {
  content: '\e96c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-red_apple .path8:before {
  content: '\e96d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-red_apple .path9:before {
  content: '\e96e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_apple .path1:before {
  content: '\e96f';
  color: rgb(177, 204, 52);
}
.flaticon-green_apple .path2:before {
  content: '\e970';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-green_apple .path3:before {
  content: '\e971';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-green_apple .path4:before {
  content: '\e972';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-green_apple .path5:before {
  content: '\e973';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-green_apple .path6:before {
  content: '\e974';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-green_apple .path7:before {
  content: '\e975';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-green_apple .path8:before {
  content: '\e976';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-green_apple .path9:before {
  content: '\e977';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_apple .path10:before {
  content: '\e978';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_apple .path11:before {
  content: '\e979';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pear .path1:before {
  content: '\e97a';
  color: rgb(177, 204, 52);
}
.flaticon-pear .path2:before {
  content: '\e97b';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-pear .path3:before {
  content: '\e97c';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-pear .path4:before {
  content: '\e97d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pear .path5:before {
  content: '\e97e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path1:before {
  content: '\e97f';
  color: rgb(177, 204, 52);
}
.flaticon-peach .path2:before {
  content: '\e980';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-peach .path3:before {
  content: '\e981';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-peach .path4:before {
  content: '\e982';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-peach .path5:before {
  content: '\e983';
  margin-left: -1em;
  color: rgb(230, 122, 148);
}
.flaticon-peach .path6:before {
  content: '\e984';
  margin-left: -1em;
  color: rgb(230, 122, 148);
}
.flaticon-peach .path7:before {
  content: '\e985';
  margin-left: -1em;
  color: rgb(230, 122, 148);
}
.flaticon-peach .path8:before {
  content: '\e986';
  margin-left: -1em;
  color: rgb(230, 122, 148);
}
.flaticon-peach .path9:before {
  content: '\e987';
  margin-left: -1em;
  color: rgb(230, 122, 148);
}
.flaticon-peach .path10:before {
  content: '\e988';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path11:before {
  content: '\e989';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path12:before {
  content: '\e98a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path13:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path14:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path15:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peach .path16:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path1:before {
  content: '\e98f';
  color: rgb(234, 90, 71);
}
.flaticon-cherries .path2:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-cherries .path3:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-cherries .path4:before {
  content: '\e992';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-cherries .path5:before {
  content: '\e993';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-cherries .path6:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-cherries .path7:before {
  content: '\e995';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path8:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path9:before {
  content: '\e997';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path10:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path11:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cherries .path12:before {
  content: '\e99a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path1:before {
  content: '\e99b';
  color: rgb(234, 90, 71);
}
.flaticon-strawberry-color .path2:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-strawberry-color .path3:before {
  content: '\e99d';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-strawberry-color .path4:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path5:before {
  content: '\e99f';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-strawberry-color .path6:before {
  content: '\e9a0';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path7:before {
  content: '\e9a1';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path8:before {
  content: '\e9a2';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path9:before {
  content: '\e9a3';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path10:before {
  content: '\e9a4';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path11:before {
  content: '\e9a5';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path12:before {
  content: '\e9a6';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path13:before {
  content: '\e9a7';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path14:before {
  content: '\e9a8';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-strawberry-color .path15:before {
  content: '\e9a9';
  margin-left: -1em;
  color: rgb(24, 103, 72);
}
.flaticon-strawberry-color .path16:before {
  content: '\e9aa';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-strawberry-color .path17:before {
  content: '\e9ab';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-strawberry-color .path18:before {
  content: '\e9ac';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-strawberry-color .path19:before {
  content: '\e9ad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path20:before {
  content: '\e9ae';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path21:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path22:before {
  content: '\e9b0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path23:before {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path24:before {
  content: '\e9b2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path25:before {
  content: '\e9b3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path26:before {
  content: '\e9b4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path27:before {
  content: '\e9b5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path28:before {
  content: '\e9b6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path29:before {
  content: '\e9b7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path30:before {
  content: '\e9b8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path31:before {
  content: '\e9b9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path32:before {
  content: '\e9ba';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-strawberry-color .path33:before {
  content: '\e9bb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path1:before {
  content: '\e9bc';
  color: rgb(91, 158, 50);
}
.flaticon-kiwi_fruit .path2:before {
  content: '\e9bd';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-kiwi_fruit .path3:before {
  content: '\e9be';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path4:before {
  content: '\e9bf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path5:before {
  content: '\e9c0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path6:before {
  content: '\e9c1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path7:before {
  content: '\e9c2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path8:before {
  content: '\e9c3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path9:before {
  content: '\e9c4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path10:before {
  content: '\e9c5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-kiwi_fruit .path11:before {
  content: '\e9c6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tomato .path1:before {
  content: '\e9c7';
  color: rgb(234, 90, 71);
}
.flaticon-tomato .path2:before {
  content: '\e9c8';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-tomato .path3:before {
  content: '\e9c9';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-tomato .path4:before {
  content: '\e9ca';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-tomato .path5:before {
  content: '\e9cb';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-tomato .path6:before {
  content: '\e9cc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tomato .path7:before {
  content: '\e9cd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-coconut .path1:before {
  content: '\e9ce';
  color: rgb(165, 121, 56);
}
.flaticon-coconut .path2:before {
  content: '\e9cf';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-coconut .path3:before {
  content: '\e9d0';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-coconut .path4:before {
  content: '\e9d1';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-coconut .path5:before {
  content: '\e9d2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-coconut .path6:before {
  content: '\e9d3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-coconut .path7:before {
  content: '\e9d4';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-coconut .path8:before {
  content: '\e9d5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-coconut .path9:before {
  content: '\e9d6';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-coconut .path10:before {
  content: '\e9d7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-coconut .path11:before {
  content: '\e9d8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-coconut .path12:before {
  content: '\e9d9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-coconut .path13:before {
  content: '\e9da';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-avocado .path1:before {
  content: '\e9db';
  color: rgb(177, 204, 52);
}
.flaticon-avocado .path2:before {
  content: '\e9dc';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-avocado .path3:before {
  content: '\e9dd';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-avocado .path4:before {
  content: '\e9de';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-avocado .path5:before {
  content: '\e9df';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-avocado .path6:before {
  content: '\e9e0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-eggplant .path1:before {
  content: '\e9e1';
  color: rgb(137, 103, 170);
}
.flaticon-eggplant .path2:before {
  content: '\e9e2';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-eggplant .path3:before {
  content: '\e9e3';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-eggplant .path4:before {
  content: '\e9e4';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-eggplant .path5:before {
  content: '\e9e5';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-eggplant .path6:before {
  content: '\e9e6';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-eggplant .path7:before {
  content: '\e9e7';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-eggplant .path8:before {
  content: '\e9e8';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-eggplant .path9:before {
  content: '\e9e9';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-eggplant .path10:before {
  content: '\e9ea';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-eggplant .path11:before {
  content: '\e9eb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-eggplant .path12:before {
  content: '\e9ec';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-eggplant .path13:before {
  content: '\e9ed';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-potato .path1:before {
  content: '\e9ee';
  color: rgb(165, 121, 56);
}
.flaticon-potato .path2:before {
  content: '\e9ef';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-potato .path3:before {
  content: '\e9f0';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-potato .path4:before {
  content: '\e9f1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-potato .path5:before {
  content: '\e9f2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-potato .path6:before {
  content: '\e9f3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-carrot .path1:before {
  content: '\e9f4';
  color: rgb(177, 204, 52);
}
.flaticon-carrot .path2:before {
  content: '\e9f5';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-carrot .path3:before {
  content: '\e9f6';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-carrot .path4:before {
  content: '\e9f7';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-carrot .path5:before {
  content: '\e9f8';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-carrot .path6:before {
  content: '\e9f9';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-carrot .path7:before {
  content: '\e9fa';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-carrot .path8:before {
  content: '\e9fb';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-carrot .path9:before {
  content: '\e9fc';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-carrot .path10:before {
  content: '\e9fd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-carrot .path11:before {
  content: '\e9fe';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ear_of_corn .path1:before {
  content: '\e9ff';
  color: rgb(252, 234, 45);
}
.flaticon-ear_of_corn .path2:before {
  content: '\ea00';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-ear_of_corn .path3:before {
  content: '\ea01';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-ear_of_corn .path4:before {
  content: '\ea02';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-ear_of_corn .path5:before {
  content: '\ea03';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-ear_of_corn .path6:before {
  content: '\ea04';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-ear_of_corn .path7:before {
  content: '\ea05';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-ear_of_corn .path8:before {
  content: '\ea06';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ear_of_corn .path9:before {
  content: '\ea07';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ear_of_corn .path10:before {
  content: '\ea08';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_pepper .path1:before {
  content: '\ea09';
  color: rgb(234, 90, 71);
}
.flaticon-hot_pepper .path2:before {
  content: '\ea0a';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-hot_pepper .path3:before {
  content: '\ea0b';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hot_pepper .path4:before {
  content: '\ea0c';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hot_pepper .path5:before {
  content: '\ea0d';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-hot_pepper .path6:before {
  content: '\ea0e';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-hot_pepper .path7:before {
  content: '\ea0f';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-hot_pepper .path8:before {
  content: '\ea10';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_pepper .path9:before {
  content: '\ea11';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_pepper .path10:before {
  content: '\ea12';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_pepper .path11:before {
  content: '\ea13';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_pepper .path12:before {
  content: '\ea14';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cucumber .path1:before {
  content: '\ea15';
  color: rgb(177, 204, 52);
}
.flaticon-cucumber .path2:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-cucumber .path3:before {
  content: '\ea17';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-leafy_green .path1:before {
  content: '\ea18';
  color: rgb(24, 103, 72);
}
.flaticon-leafy_green .path2:before {
  content: '\ea19';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-leafy_green .path3:before {
  content: '\ea1a';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-leafy_green .path4:before {
  content: '\ea1b';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-leafy_green .path5:before {
  content: '\ea1c';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-leafy_green .path6:before {
  content: '\ea1d';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-leafy_green .path7:before {
  content: '\ea1e';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-leafy_green .path8:before {
  content: '\ea1f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path1:before {
  content: '\ea20';
  color: rgb(177, 204, 52);
}
.flaticon-broccoli .path2:before {
  content: '\ea21';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-broccoli .path3:before {
  content: '\ea22';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-broccoli .path4:before {
  content: '\ea23';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-broccoli .path5:before {
  content: '\ea24';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-broccoli .path6:before {
  content: '\ea25';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-broccoli .path7:before {
  content: '\ea26';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-broccoli .path8:before {
  content: '\ea27';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-broccoli .path9:before {
  content: '\ea28';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path10:before {
  content: '\ea29';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path11:before {
  content: '\ea2a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path12:before {
  content: '\ea2b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path13:before {
  content: '\ea2c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-broccoli .path14:before {
  content: '\ea2d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path1:before {
  content: '\ea2e';
  color: rgb(156, 155, 154);
}
.flaticon-garlic .path2:before {
  content: '\ea2f';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-garlic .path3:before {
  content: '\ea30';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path4:before {
  content: '\ea31';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path5:before {
  content: '\ea32';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path6:before {
  content: '\ea33';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path7:before {
  content: '\ea34';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path8:before {
  content: '\ea35';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path9:before {
  content: '\ea36';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path10:before {
  content: '\ea37';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path11:before {
  content: '\ea38';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path12:before {
  content: '\ea39';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path13:before {
  content: '\ea3a';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-garlic .path14:before {
  content: '\ea3b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path15:before {
  content: '\ea3c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path16:before {
  content: '\ea3d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path17:before {
  content: '\ea3e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path18:before {
  content: '\ea3f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path19:before {
  content: '\ea40';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path20:before {
  content: '\ea41';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path21:before {
  content: '\ea42';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path22:before {
  content: '\ea43';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path23:before {
  content: '\ea44';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path24:before {
  content: '\ea45';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path25:before {
  content: '\ea46';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path26:before {
  content: '\ea47';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-garlic .path27:before {
  content: '\ea48';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path1:before {
  content: '\ea49';
  color: rgb(245, 171, 65);
}
.flaticon-onion .path2:before {
  content: '\ea4a';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-onion .path3:before {
  content: '\ea4b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path4:before {
  content: '\ea4c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path5:before {
  content: '\ea4d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path6:before {
  content: '\ea4e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path7:before {
  content: '\ea4f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path8:before {
  content: '\ea50';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-onion .path9:before {
  content: '\ea51';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mushroom .path1:before {
  content: '\ea52';
  color: rgb(208, 207, 206);
}
.flaticon-mushroom .path2:before {
  content: '\ea53';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-mushroom .path3:before {
  content: '\ea54';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-mushroom .path4:before {
  content: '\ea55';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-mushroom .path5:before {
  content: '\ea56';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-mushroom .path6:before {
  content: '\ea57';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-mushroom .path7:before {
  content: '\ea58';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-mushroom .path8:before {
  content: '\ea59';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-mushroom .path9:before {
  content: '\ea5a';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-mushroom .path10:before {
  content: '\ea5b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mushroom .path11:before {
  content: '\ea5c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mushroom .path12:before {
  content: '\ea5d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mushroom .path13:before {
  content: '\ea5e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mushroom .path14:before {
  content: '\ea5f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-peanuts .path1:before {
  content: '\ea60';
  color: rgb(106, 70, 47);
}
.flaticon-peanuts .path2:before {
  content: '\ea61';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-peanuts .path3:before {
  content: '\ea62';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-peanuts .path4:before {
  content: '\ea63';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-peanuts .path5:before {
  content: '\ea64';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chestnut .path1:before {
  content: '\ea65';
  color: rgb(165, 121, 56);
}
.flaticon-chestnut .path2:before {
  content: '\ea66';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-chestnut .path3:before {
  content: '\ea67';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-chestnut .path4:before {
  content: '\ea68';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-chestnut .path5:before {
  content: '\ea69';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-chestnut .path6:before {
  content: '\ea6a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chestnut .path7:before {
  content: '\ea6b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chestnut .path8:before {
  content: '\ea6c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chestnut .path9:before {
  content: '\ea6d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chestnut .path10:before {
  content: '\ea6e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bread .path1:before {
  content: '\ea6f';
  color: rgb(240, 179, 27);
}
.flaticon-bread .path2:before {
  content: '\ea70';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-bread .path3:before {
  content: '\ea71';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bread .path4:before {
  content: '\ea72';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path1:before {
  content: '\ea73';
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path2:before {
  content: '\ea74';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path3:before {
  content: '\ea75';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path4:before {
  content: '\ea76';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path5:before {
  content: '\ea77';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path6:before {
  content: '\ea78';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path7:before {
  content: '\ea79';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-croissant .path8:before {
  content: '\ea7a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path9:before {
  content: '\ea7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path10:before {
  content: '\ea7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path11:before {
  content: '\ea7d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path12:before {
  content: '\ea7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path13:before {
  content: '\ea7f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-croissant .path14:before {
  content: '\ea80';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baguette_bread .path1:before {
  content: '\ea81';
  color: rgb(245, 171, 65);
}
.flaticon-baguette_bread .path2:before {
  content: '\ea82';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baguette_bread .path3:before {
  content: '\ea83';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baguette_bread .path4:before {
  content: '\ea84';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baguette_bread .path5:before {
  content: '\ea85';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baguette_bread .path6:before {
  content: '\ea86';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path1:before {
  content: '\ea87';
  color: rgb(245, 171, 65);
}
.flaticon-pretzel-color .path2:before {
  content: '\ea88';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path3:before {
  content: '\ea89';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path4:before {
  content: '\ea8a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path5:before {
  content: '\ea8b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path6:before {
  content: '\ea8c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path7:before {
  content: '\ea8d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pretzel-color .path8:before {
  content: '\ea8e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path1:before {
  content: '\ea8f';
  color: rgb(106, 70, 47);
}
.flaticon-bagel .path2:before {
  content: '\ea90';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-bagel .path3:before {
  content: '\ea91';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-bagel .path4:before {
  content: '\ea92';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-bagel .path5:before {
  content: '\ea93';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-bagel .path6:before {
  content: '\ea94';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path7:before {
  content: '\ea95';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path8:before {
  content: '\ea96';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path9:before {
  content: '\ea97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path10:before {
  content: '\ea98';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path11:before {
  content: '\ea99';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path12:before {
  content: '\ea9a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path13:before {
  content: '\ea9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path14:before {
  content: '\ea9c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path15:before {
  content: '\ea9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path16:before {
  content: '\ea9e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path17:before {
  content: '\ea9f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path18:before {
  content: '\eaa0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path19:before {
  content: '\eaa1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path20:before {
  content: '\eaa2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path21:before {
  content: '\eaa3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bagel .path22:before {
  content: '\eaa4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path1:before {
  content: '\eaa5';
  color: rgb(208, 207, 206);
}
.flaticon-pancakes .path2:before {
  content: '\eaa6';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path3:before {
  content: '\eaa7';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path4:before {
  content: '\eaa8';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path5:before {
  content: '\eaa9';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path6:before {
  content: '\eaaa';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path7:before {
  content: '\eaab';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path8:before {
  content: '\eaac';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path9:before {
  content: '\eaad';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pancakes .path10:before {
  content: '\eaae';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-pancakes .path11:before {
  content: '\eaaf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path12:before {
  content: '\eab0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path13:before {
  content: '\eab1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path14:before {
  content: '\eab2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path15:before {
  content: '\eab3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path16:before {
  content: '\eab4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pancakes .path17:before {
  content: '\eab5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path1:before {
  content: '\eab6';
  color: rgb(245, 171, 65);
}
.flaticon-waffle .path2:before {
  content: '\eab7';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path3:before {
  content: '\eab8';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path4:before {
  content: '\eab9';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path5:before {
  content: '\eaba';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path6:before {
  content: '\eabb';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path7:before {
  content: '\eabc';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path8:before {
  content: '\eabd';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path9:before {
  content: '\eabe';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path10:before {
  content: '\eabf';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path11:before {
  content: '\eac0';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-waffle .path12:before {
  content: '\eac1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path13:before {
  content: '\eac2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path14:before {
  content: '\eac3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path15:before {
  content: '\eac4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path16:before {
  content: '\eac5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path17:before {
  content: '\eac6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path18:before {
  content: '\eac7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path19:before {
  content: '\eac8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path20:before {
  content: '\eac9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-waffle .path21:before {
  content: '\eaca';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path1:before {
  content: '\eacb';
  color: rgb(252, 234, 45);
}
.flaticon-cheese_wedge .path2:before {
  content: '\eacc';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path3:before {
  content: '\eacd';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path4:before {
  content: '\eace';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path5:before {
  content: '\eacf';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path6:before {
  content: '\ead0';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path7:before {
  content: '\ead1';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-cheese_wedge .path8:before {
  content: '\ead2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path9:before {
  content: '\ead3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path10:before {
  content: '\ead4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path11:before {
  content: '\ead5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path12:before {
  content: '\ead6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path13:before {
  content: '\ead7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path14:before {
  content: '\ead8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cheese_wedge .path15:before {
  content: '\ead9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path1:before {
  content: '\eada';
  color: rgb(245, 171, 65);
}
.flaticon-meat_on_bone .path2:before {
  content: '\eadb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-meat_on_bone .path3:before {
  content: '\eadc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-meat_on_bone .path4:before {
  content: '\eadd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path5:before {
  content: '\eade';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path6:before {
  content: '\eadf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path7:before {
  content: '\eae0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path8:before {
  content: '\eae1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-meat_on_bone .path9:before {
  content: '\eae2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-poultry_leg .path1:before {
  content: '\eae3';
  color: rgb(255, 255, 255);
}
.flaticon-poultry_leg .path2:before {
  content: '\eae4';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-poultry_leg .path3:before {
  content: '\eae5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-poultry_leg .path4:before {
  content: '\eae6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cut_of_meat .path1:before {
  content: '\eae7';
  color: rgb(255, 255, 255);
}
.flaticon-cut_of_meat .path2:before {
  content: '\eae8';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-cut_of_meat .path3:before {
  content: '\eae9';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-cut_of_meat .path4:before {
  content: '\eaea';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cut_of_meat .path5:before {
  content: '\eaeb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cut_of_meat .path6:before {
  content: '\eaec';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bacon .path1:before {
  content: '\eaed';
  color: rgb(234, 90, 71);
}
.flaticon-bacon .path2:before {
  content: '\eaee';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-bacon .path3:before {
  content: '\eaef';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bacon .path4:before {
  content: '\eaf0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bacon .path5:before {
  content: '\eaf1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bacon .path6:before {
  content: '\eaf2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hamburger .path1:before {
  content: '\eaf3';
  color: rgb(165, 121, 56);
}
.flaticon-hamburger .path2:before {
  content: '\eaf4';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-hamburger .path3:before {
  content: '\eaf5';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-hamburger .path4:before {
  content: '\eaf6';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-hamburger .path5:before {
  content: '\eaf7';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-hamburger .path6:before {
  content: '\eaf8';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-hamburger .path7:before {
  content: '\eaf9';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-hamburger .path8:before {
  content: '\eafa';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-hamburger .path9:before {
  content: '\eafb';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-hamburger .path10:before {
  content: '\eafc';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hamburger .path11:before {
  content: '\eafd';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hamburger .path12:before {
  content: '\eafe';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-hamburger .path13:before {
  content: '\eaff';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-hamburger .path14:before {
  content: '\eb00';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-hamburger .path15:before {
  content: '\eb01';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-hamburger .path16:before {
  content: '\eb02';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hamburger .path17:before {
  content: '\eb03';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-hamburger .path18:before {
  content: '\eb04';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hamburger .path19:before {
  content: '\eb05';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hamburger .path20:before {
  content: '\eb06';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hamburger .path21:before {
  content: '\eb07';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hamburger .path22:before {
  content: '\eb08';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path1:before {
  content: '\eb09';
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path2:before {
  content: '\eb0a';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path3:before {
  content: '\eb0b';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path4:before {
  content: '\eb0c';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path5:before {
  content: '\eb0d';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path6:before {
  content: '\eb0e';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path7:before {
  content: '\eb0f';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path8:before {
  content: '\eb10';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path9:before {
  content: '\eb11';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-french_fries .path10:before {
  content: '\eb12';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-french_fries .path11:before {
  content: '\eb13';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path12:before {
  content: '\eb14';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path13:before {
  content: '\eb15';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path14:before {
  content: '\eb16';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path15:before {
  content: '\eb17';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path16:before {
  content: '\eb18';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path17:before {
  content: '\eb19';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path18:before {
  content: '\eb1a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path19:before {
  content: '\eb1b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-french_fries .path20:before {
  content: '\eb1c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pizza-color .path1:before {
  content: '\eb1d';
  color: rgb(245, 171, 65);
}
.flaticon-pizza-color .path2:before {
  content: '\eb1e';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pizza-color .path3:before {
  content: '\eb1f';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-pizza-color .path4:before {
  content: '\eb20';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-pizza-color .path5:before {
  content: '\eb21';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-pizza-color .path6:before {
  content: '\eb22';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pizza-color .path7:before {
  content: '\eb23';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pizza-color .path8:before {
  content: '\eb24';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pizza-color .path9:before {
  content: '\eb25';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pizza-color .path10:before {
  content: '\eb26';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path1:before {
  content: '\eb27';
  color: rgb(245, 171, 65);
}
.flaticon-hot_dog .path2:before {
  content: '\eb28';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-hot_dog .path3:before {
  content: '\eb29';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path4:before {
  content: '\eb2a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path5:before {
  content: '\eb2b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path6:before {
  content: '\eb2c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path7:before {
  content: '\eb2d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path8:before {
  content: '\eb2e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path9:before {
  content: '\eb2f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_dog .path10:before {
  content: '\eb30';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path1:before {
  content: '\eb31';
  color: rgb(177, 204, 52);
}
.flaticon-sandwich .path2:before {
  content: '\eb32';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-sandwich .path3:before {
  content: '\eb33';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-sandwich .path4:before {
  content: '\eb34';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path5:before {
  content: '\eb35';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path6:before {
  content: '\eb36';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path7:before {
  content: '\eb37';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path8:before {
  content: '\eb38';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path9:before {
  content: '\eb39';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path10:before {
  content: '\eb3a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path11:before {
  content: '\eb3b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path12:before {
  content: '\eb3c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path13:before {
  content: '\eb3d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path14:before {
  content: '\eb3e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path15:before {
  content: '\eb3f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sandwich .path16:before {
  content: '\eb40';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path1:before {
  content: '\eb41';
  color: rgb(234, 90, 71);
}
.flaticon-taco .path2:before {
  content: '\eb42';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-taco .path3:before {
  content: '\eb43';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-taco .path4:before {
  content: '\eb44';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-taco .path5:before {
  content: '\eb45';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-taco .path6:before {
  content: '\eb46';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-taco .path7:before {
  content: '\eb47';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path8:before {
  content: '\eb48';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path9:before {
  content: '\eb49';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path10:before {
  content: '\eb4a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path11:before {
  content: '\eb4b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-taco .path12:before {
  content: '\eb4c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-burrito .path1:before {
  content: '\eb4d';
  color: rgb(177, 204, 52);
}
.flaticon-burrito .path2:before {
  content: '\eb4e';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-burrito .path3:before {
  content: '\eb4f';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-burrito .path4:before {
  content: '\eb50';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-burrito .path5:before {
  content: '\eb51';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-burrito .path6:before {
  content: '\eb52';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-burrito .path7:before {
  content: '\eb53';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path1:before {
  content: '\eb54';
  color: rgb(234, 90, 71);
}
.flaticon-stuffed_flatbread .path2:before {
  content: '\eb55';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-stuffed_flatbread .path3:before {
  content: '\eb56';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-stuffed_flatbread .path4:before {
  content: '\eb57';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-stuffed_flatbread .path5:before {
  content: '\eb58';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-stuffed_flatbread .path6:before {
  content: '\eb59';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-stuffed_flatbread .path7:before {
  content: '\eb5a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path8:before {
  content: '\eb5b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path9:before {
  content: '\eb5c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path10:before {
  content: '\eb5d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path11:before {
  content: '\eb5e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path12:before {
  content: '\eb5f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path13:before {
  content: '\eb60';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path14:before {
  content: '\eb61';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path15:before {
  content: '\eb62';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path16:before {
  content: '\eb63';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path17:before {
  content: '\eb64';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path18:before {
  content: '\eb65';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path19:before {
  content: '\eb66';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-stuffed_flatbread .path20:before {
  content: '\eb67';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-falafel .path1:before {
  content: '\eb68';
  color: rgb(255, 255, 255);
}
.flaticon-falafel .path2:before {
  content: '\eb69';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-falafel .path3:before {
  content: '\eb6a';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-falafel .path4:before {
  content: '\eb6b';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-falafel .path5:before {
  content: '\eb6c';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-falafel .path6:before {
  content: '\eb6d';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-falafel .path7:before {
  content: '\eb6e';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-falafel .path8:before {
  content: '\eb6f';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-falafel .path9:before {
  content: '\eb70';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.99;
}
.flaticon-falafel .path10:before {
  content: '\eb71';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-falafel .path11:before {
  content: '\eb72';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.99;
}
.flaticon-falafel .path12:before {
  content: '\eb73';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.99;
}
.flaticon-falafel .path13:before {
  content: '\eb74';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.99;
}
.flaticon-falafel .path14:before {
  content: '\eb75';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooking .path1:before {
  content: '\eb76';
  color: rgb(156, 155, 154);
}
.flaticon-cooking .path2:before {
  content: '\eb77';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-cooking .path3:before {
  content: '\eb78';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-cooking .path4:before {
  content: '\eb79';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-cooking .path5:before {
  content: '\eb7a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooking .path6:before {
  content: '\eb7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooking .path7:before {
  content: '\eb7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooking .path8:before {
  content: '\eb7d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path1:before {
  content: '\eb7e';
  color: rgb(63, 63, 63);
}
.flaticon-shallow_pan_of_food .path2:before {
  content: '\eb7f';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-shallow_pan_of_food .path3:before {
  content: '\eb80';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path4:before {
  content: '\eb81';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path5:before {
  content: '\eb82';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-shallow_pan_of_food .path6:before {
  content: '\eb83';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-shallow_pan_of_food .path7:before {
  content: '\eb84';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path8:before {
  content: '\eb85';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path9:before {
  content: '\eb86';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path10:before {
  content: '\eb87';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path11:before {
  content: '\eb88';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path12:before {
  content: '\eb89';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-shallow_pan_of_food .path13:before {
  content: '\eb8a';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-shallow_pan_of_food .path14:before {
  content: '\eb8b';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-shallow_pan_of_food .path15:before {
  content: '\eb8c';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-shallow_pan_of_food .path16:before {
  content: '\eb8d';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-shallow_pan_of_food .path17:before {
  content: '\eb8e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path18:before {
  content: '\eb8f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path19:before {
  content: '\eb90';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path20:before {
  content: '\eb91';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path21:before {
  content: '\eb92';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path22:before {
  content: '\eb93';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path23:before {
  content: '\eb94';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-shallow_pan_of_food .path24:before {
  content: '\eb95';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path25:before {
  content: '\eb96';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path26:before {
  content: '\eb97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shallow_pan_of_food .path27:before {
  content: '\eb98';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pot_of_food .path1:before {
  content: '\eb99';
  color: rgb(255, 255, 255);
}
.flaticon-pot_of_food .path2:before {
  content: '\eb9a';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-pot_of_food .path3:before {
  content: '\eb9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pot_of_food .path4:before {
  content: '\eb9c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pot_of_food .path5:before {
  content: '\eb9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pot_of_food .path6:before {
  content: '\eb9e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pot_of_food .path7:before {
  content: '\eb9f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bowl_with_spoon .path1:before {
  content: '\eba0';
  color: rgb(255, 255, 255);
}
.flaticon-bowl_with_spoon .path2:before {
  content: '\eba1';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-bowl_with_spoon .path3:before {
  content: '\eba2';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-bowl_with_spoon .path4:before {
  content: '\eba3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bowl_with_spoon .path5:before {
  content: '\eba4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_salad .path1:before {
  content: '\eba5';
  color: rgb(177, 204, 52);
}
.flaticon-green_salad .path2:before {
  content: '\eba6';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-green_salad .path3:before {
  content: '\eba7';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-green_salad .path4:before {
  content: '\eba8';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-green_salad .path5:before {
  content: '\eba9';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-green_salad .path6:before {
  content: '\ebaa';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-green_salad .path7:before {
  content: '\ebab';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-green_salad .path8:before {
  content: '\ebac';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_salad .path9:before {
  content: '\ebad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_salad .path10:before {
  content: '\ebae';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_salad .path11:before {
  content: '\ebaf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-green_salad .path12:before {
  content: '\ebb0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-popcorn .path1:before {
  content: '\ebb1';
  color: rgb(234, 90, 71);
}
.flaticon-popcorn .path2:before {
  content: '\ebb2';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-popcorn .path3:before {
  content: '\ebb3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-popcorn .path4:before {
  content: '\ebb4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-popcorn .path5:before {
  content: '\ebb5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-popcorn .path6:before {
  content: '\ebb6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-butter .path1:before {
  content: '\ebb7';
  color: rgb(252, 234, 45);
}
.flaticon-butter .path2:before {
  content: '\ebb8';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-butter .path3:before {
  content: '\ebb9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-butter .path4:before {
  content: '\ebba';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-butter .path5:before {
  content: '\ebbb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-butter .path6:before {
  content: '\ebbc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-butter .path7:before {
  content: '\ebbd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-salt .path1:before {
  content: '\ebbe';
  color: rgb(255, 255, 255);
}
.flaticon-salt .path2:before {
  content: '\ebbf';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-salt .path3:before {
  content: '\ebc0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-salt .path4:before {
  content: '\ebc1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-salt .path5:before {
  content: '\ebc2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-salt .path6:before {
  content: '\ebc3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path1:before {
  content: '\ebc4';
  color: rgb(208, 207, 206);
}
.flaticon-canned_food .path2:before {
  content: '\ebc5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-canned_food .path3:before {
  content: '\ebc6';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-canned_food .path4:before {
  content: '\ebc7';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-canned_food .path5:before {
  content: '\ebc8';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-canned_food .path6:before {
  content: '\ebc9';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-canned_food .path7:before {
  content: '\ebca';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-canned_food .path8:before {
  content: '\ebcb';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-canned_food .path9:before {
  content: '\ebcc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path10:before {
  content: '\ebcd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path11:before {
  content: '\ebce';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path12:before {
  content: '\ebcf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path13:before {
  content: '\ebd0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-canned_food .path14:before {
  content: '\ebd1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path1:before {
  content: '\ebd2';
  color: rgb(255, 255, 255);
}
.flaticon-bento_box .path2:before {
  content: '\ebd3';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-bento_box .path3:before {
  content: '\ebd4';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-bento_box .path4:before {
  content: '\ebd5';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-bento_box .path5:before {
  content: '\ebd6';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-bento_box .path6:before {
  content: '\ebd7';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-bento_box .path7:before {
  content: '\ebd8';
  margin-left: -1em;
  color: rgb(97, 177, 227);
}
.flaticon-bento_box .path8:before {
  content: '\ebd9';
  margin-left: -1em;
  color: rgb(147, 211, 245);
}
.flaticon-bento_box .path9:before {
  content: '\ebda';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path10:before {
  content: '\ebdb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path11:before {
  content: '\ebdc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path12:before {
  content: '\ebdd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path13:before {
  content: '\ebde';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path14:before {
  content: '\ebdf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path15:before {
  content: '\ebe0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path16:before {
  content: '\ebe1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path17:before {
  content: '\ebe2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path18:before {
  content: '\ebe3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path19:before {
  content: '\ebe4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path20:before {
  content: '\ebe5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path21:before {
  content: '\ebe6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path22:before {
  content: '\ebe7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path23:before {
  content: '\ebe8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path24:before {
  content: '\ebe9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bento_box .path25:before {
  content: '\ebea';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-rice_cracker .path1:before {
  content: '\ebeb';
  color: rgb(245, 171, 65);
}
.flaticon-rice_cracker .path2:before {
  content: '\ebec';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-rice_cracker .path3:before {
  content: '\ebed';
  margin-left: -1em;
  color: rgb(63, 63, 63);
}
.flaticon-rice_cracker .path4:before {
  content: '\ebee';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-rice_cracker .path5:before {
  content: '\ebef';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-rice_ball .path1:before {
  content: '\ebf0';
  color: rgb(255, 255, 255);
}
.flaticon-rice_ball .path2:before {
  content: '\ebf1';
  margin-left: -1em;
  color: rgb(63, 63, 63);
}
.flaticon-rice_ball .path3:before {
  content: '\ebf2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-rice_ball .path4:before {
  content: '\ebf3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-rice_ball .path5:before {
  content: '\ebf4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooked_rice .path1:before {
  content: '\ebf5';
  color: rgb(255, 255, 255);
}
.flaticon-cooked_rice .path2:before {
  content: '\ebf6';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-cooked_rice .path3:before {
  content: '\ebf7';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-cooked_rice .path4:before {
  content: '\ebf8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cooked_rice .path5:before {
  content: '\ebf9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-curry_rice .path1:before {
  content: '\ebfa';
  color: rgb(208, 207, 206);
}
.flaticon-curry_rice .path2:before {
  content: '\ebfb';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-curry_rice .path3:before {
  content: '\ebfc';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-curry_rice .path4:before {
  content: '\ebfd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-curry_rice .path5:before {
  content: '\ebfe';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-curry_rice .path6:before {
  content: '\ebff';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-curry_rice .path7:before {
  content: '\ec00';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-curry_rice .path8:before {
  content: '\ec01';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-curry_rice .path9:before {
  content: '\ec02';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-curry_rice .path10:before {
  content: '\ec03';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-curry_rice .path11:before {
  content: '\ec04';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path1:before {
  content: '\ec05';
  color: rgb(210, 47, 39);
}
.flaticon-steaming_bowl .path2:before {
  content: '\ec06';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path3:before {
  content: '\ec07';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path4:before {
  content: '\ec08';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path5:before {
  content: '\ec09';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path6:before {
  content: '\ec0a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path7:before {
  content: '\ec0b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-steaming_bowl .path8:before {
  content: '\ec0c';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-steaming_bowl .path9:before {
  content: '\ec0d';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-steaming_bowl .path10:before {
  content: '\ec0e';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-spaghetti-color .path1:before {
  content: '\ec0f';
  color: rgb(252, 234, 45);
}
.flaticon-spaghetti-color .path2:before {
  content: '\ec10';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-spaghetti-color .path3:before {
  content: '\ec11';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-spaghetti-color .path4:before {
  content: '\ec12';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-spaghetti-color .path5:before {
  content: '\ec13';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-spaghetti-color .path6:before {
  content: '\ec14';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path7:before {
  content: '\ec15';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path8:before {
  content: '\ec16';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path9:before {
  content: '\ec17';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path10:before {
  content: '\ec18';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path11:before {
  content: '\ec19';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path12:before {
  content: '\ec1a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path13:before {
  content: '\ec1b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path14:before {
  content: '\ec1c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path15:before {
  content: '\ec1d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spaghetti-color .path16:before {
  content: '\ec1e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path1:before {
  content: '\ec1f';
  color: rgb(245, 171, 65);
}
.flaticon-roasted_sweet_potato .path2:before {
  content: '\ec20';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-roasted_sweet_potato .path3:before {
  content: '\ec21';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path4:before {
  content: '\ec22';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path5:before {
  content: '\ec23';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path6:before {
  content: '\ec24';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path7:before {
  content: '\ec25';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path8:before {
  content: '\ec26';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-roasted_sweet_potato .path9:before {
  content: '\ec27';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oden .path1:before {
  content: '\ec28';
  color: rgb(245, 171, 65);
}
.flaticon-oden .path2:before {
  content: '\ec29';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-oden .path3:before {
  content: '\ec2a';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-oden .path4:before {
  content: '\ec2b';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-oden .path5:before {
  content: '\ec2c';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-oden .path6:before {
  content: '\ec2d';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-oden .path7:before {
  content: '\ec2e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oden .path8:before {
  content: '\ec2f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oden .path9:before {
  content: '\ec30';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oden .path10:before {
  content: '\ec31';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sushi .path1:before {
  content: '\ec32';
  color: rgb(255, 255, 255);
}
.flaticon-sushi .path2:before {
  content: '\ec33';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-sushi .path3:before {
  content: '\ec34';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sushi .path4:before {
  content: '\ec35';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fried_shrimp .path1:before {
  content: '\ec36';
  color: rgb(234, 90, 71);
}
.flaticon-fried_shrimp .path2:before {
  content: '\ec37';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-fried_shrimp .path3:before {
  content: '\ec38';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fried_shrimp .path4:before {
  content: '\ec39';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path1:before {
  content: '\ec3a';
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path2:before {
  content: '\ec3b';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path3:before {
  content: '\ec3c';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path4:before {
  content: '\ec3d';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path5:before {
  content: '\ec3e';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path6:before {
  content: '\ec3f';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path7:before {
  content: '\ec40';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path8:before {
  content: '\ec41';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path9:before {
  content: '\ec42';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path10:before {
  content: '\ec43';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path11:before {
  content: '\ec44';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fish_cake_with_swirl .path12:before {
  content: '\ec45';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-fish_cake_with_swirl .path13:before {
  content: '\ec46';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path14:before {
  content: '\ec47';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path15:before {
  content: '\ec48';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path16:before {
  content: '\ec49';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path17:before {
  content: '\ec4a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path18:before {
  content: '\ec4b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path19:before {
  content: '\ec4c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path20:before {
  content: '\ec4d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path21:before {
  content: '\ec4e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path22:before {
  content: '\ec4f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fish_cake_with_swirl .path23:before {
  content: '\ec50';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-moon_cake .path1:before {
  content: '\ec51';
  color: rgb(165, 121, 56);
}
.flaticon-moon_cake .path2:before {
  content: '\ec52';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-moon_cake .path3:before {
  content: '\ec53';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-moon_cake .path4:before {
  content: '\ec54';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-moon_cake .path5:before {
  content: '\ec55';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path6:before {
  content: '\ec56';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path7:before {
  content: '\ec57';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path8:before {
  content: '\ec58';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path9:before {
  content: '\ec59';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path10:before {
  content: '\ec5a';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path11:before {
  content: '\ec5b';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-moon_cake .path12:before {
  content: '\ec5c';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-dango .path1:before {
  content: '\ec5d';
  color: rgb(255, 255, 255);
}
.flaticon-dango .path2:before {
  content: '\ec5e';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-dango .path3:before {
  content: '\ec5f';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-dango .path4:before {
  content: '\ec60';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-dango .path5:before {
  content: '\ec61';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-dango .path6:before {
  content: '\ec62';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-dango .path7:before {
  content: '\ec63';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-dango .path8:before {
  content: '\ec64';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-dango .path9:before {
  content: '\ec65';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-dango .path10:before {
  content: '\ec66';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-dumpling .path1:before {
  content: '\ec67';
  color: rgb(252, 234, 45);
}
.flaticon-dumpling .path2:before {
  content: '\ec68';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-dumpling .path3:before {
  content: '\ec69';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-dumpling .path4:before {
  content: '\ec6a';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-dumpling .path5:before {
  content: '\ec6b';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-dumpling .path6:before {
  content: '\ec6c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fortune_cookie .path1:before {
  content: '\ec6d';
  color: rgb(208, 207, 206);
}
.flaticon-fortune_cookie .path2:before {
  content: '\ec6e';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-fortune_cookie .path3:before {
  content: '\ec6f';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-fortune_cookie .path4:before {
  content: '\ec70';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fortune_cookie .path5:before {
  content: '\ec71';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path1:before {
  content: '\ec72';
  color: rgb(210, 47, 39);
}
.flaticon-takeout_box .path2:before {
  content: '\ec73';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-takeout_box .path3:before {
  content: '\ec74';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-takeout_box .path4:before {
  content: '\ec75';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path5:before {
  content: '\ec76';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path6:before {
  content: '\ec77';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path7:before {
  content: '\ec78';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path8:before {
  content: '\ec79';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path9:before {
  content: '\ec7a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path10:before {
  content: '\ec7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path11:before {
  content: '\ec7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path12:before {
  content: '\ec7d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path13:before {
  content: '\ec7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path14:before {
  content: '\ec7f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path15:before {
  content: '\ec80';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path16:before {
  content: '\ec81';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path17:before {
  content: '\ec82';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path18:before {
  content: '\ec83';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path19:before {
  content: '\ec84';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-takeout_box .path20:before {
  content: '\ec85';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-takeout_box .path21:before {
  content: '\ec86';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-takeout_box .path22:before {
  content: '\ec87';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oyster .path1:before {
  content: '\ec88';
  color: rgb(137, 103, 170);
}
.flaticon-oyster .path2:before {
  content: '\ec89';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-oyster .path3:before {
  content: '\ec8a';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-oyster .path4:before {
  content: '\ec8b';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-oyster .path5:before {
  content: '\ec8c';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-oyster .path6:before {
  content: '\ec8d';
  margin-left: -1em;
  color: rgb(178, 153, 201);
}
.flaticon-oyster .path7:before {
  content: '\ec8e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oyster .path8:before {
  content: '\ec8f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oyster .path9:before {
  content: '\ec90';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-oyster .path10:before {
  content: '\ec91';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path1:before {
  content: '\ec92';
  color: rgb(255, 255, 255);
}
.flaticon-soft_ice_cream .path2:before {
  content: '\ec93';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-soft_ice_cream .path3:before {
  content: '\ec94';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-soft_ice_cream .path4:before {
  content: '\ec95';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-soft_ice_cream .path5:before {
  content: '\ec96';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-soft_ice_cream .path6:before {
  content: '\ec97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path7:before {
  content: '\ec98';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path8:before {
  content: '\ec99';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path9:before {
  content: '\ec9a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path10:before {
  content: '\ec9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path11:before {
  content: '\ec9c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path12:before {
  content: '\ec9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path13:before {
  content: '\ec9e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-soft_ice_cream .path14:before {
  content: '\ec9f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path1:before {
  content: '\eca0';
  color: rgb(252, 234, 45);
}
.flaticon-shaved_ice .path2:before {
  content: '\eca1';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-shaved_ice .path3:before {
  content: '\eca2';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-shaved_ice .path4:before {
  content: '\eca3';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-shaved_ice .path5:before {
  content: '\eca4';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-shaved_ice .path6:before {
  content: '\eca5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-shaved_ice .path7:before {
  content: '\eca6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path8:before {
  content: '\eca7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path9:before {
  content: '\eca8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path10:before {
  content: '\eca9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path11:before {
  content: '\ecaa';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shaved_ice .path12:before {
  content: '\ecab';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice_cream .path1:before {
  content: '\ecac';
  color: rgb(252, 234, 45);
}
.flaticon-ice_cream .path2:before {
  content: '\ecad';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-ice_cream .path3:before {
  content: '\ecae';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-ice_cream .path4:before {
  content: '\ecaf';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-ice_cream .path5:before {
  content: '\ecb0';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-ice_cream .path6:before {
  content: '\ecb1';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-ice_cream .path7:before {
  content: '\ecb2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-ice_cream .path8:before {
  content: '\ecb3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice_cream .path9:before {
  content: '\ecb4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice_cream .path10:before {
  content: '\ecb5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice_cream .path11:before {
  content: '\ecb6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice_cream .path12:before {
  content: '\ecb7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path1:before {
  content: '\ecb8';
  color: rgb(252, 234, 45);
}
.flaticon-doughnut-color .path2:before {
  content: '\ecb9';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-doughnut-color .path3:before {
  content: '\ecba';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path4:before {
  content: '\ecbb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path5:before {
  content: '\ecbc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path6:before {
  content: '\ecbd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path7:before {
  content: '\ecbe';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path8:before {
  content: '\ecbf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path9:before {
  content: '\ecc0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path10:before {
  content: '\ecc1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-doughnut-color .path11:before {
  content: '\ecc2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path12:before {
  content: '\ecc3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path13:before {
  content: '\ecc4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path14:before {
  content: '\ecc5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path15:before {
  content: '\ecc6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path16:before {
  content: '\ecc7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path17:before {
  content: '\ecc8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path18:before {
  content: '\ecc9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path19:before {
  content: '\ecca';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path20:before {
  content: '\eccb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path21:before {
  content: '\eccc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-doughnut-color .path22:before {
  content: '\eccd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path1:before {
  content: '\ecce';
  color: rgb(165, 121, 56);
}
.flaticon-cookie-color .path2:before {
  content: '\eccf';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path3:before {
  content: '\ecd0';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path4:before {
  content: '\ecd1';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path5:before {
  content: '\ecd2';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path6:before {
  content: '\ecd3';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path7:before {
  content: '\ecd4';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path8:before {
  content: '\ecd5';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-cookie-color .path9:before {
  content: '\ecd6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path10:before {
  content: '\ecd7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path11:before {
  content: '\ecd8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path12:before {
  content: '\ecd9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path13:before {
  content: '\ecda';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path14:before {
  content: '\ecdb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path15:before {
  content: '\ecdc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cookie-color .path16:before {
  content: '\ecdd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path1:before {
  content: '\ecde';
  color: rgb(252, 234, 45);
}
.flaticon-birthday_cake .path2:before {
  content: '\ecdf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-birthday_cake .path3:before {
  content: '\ece0';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-birthday_cake .path4:before {
  content: '\ece1';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-birthday_cake .path5:before {
  content: '\ece2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-birthday_cake .path6:before {
  content: '\ece3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path7:before {
  content: '\ece4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path8:before {
  content: '\ece5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path9:before {
  content: '\ece6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path10:before {
  content: '\ece7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path11:before {
  content: '\ece8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-birthday_cake .path12:before {
  content: '\ece9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path1:before {
  content: '\ecea';
  color: rgb(255, 255, 255);
}
.flaticon-shortcake .path2:before {
  content: '\eceb';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-shortcake .path3:before {
  content: '\ecec';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-shortcake .path4:before {
  content: '\eced';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-shortcake .path5:before {
  content: '\ecee';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path6:before {
  content: '\ecef';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path7:before {
  content: '\ecf0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path8:before {
  content: '\ecf1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path9:before {
  content: '\ecf2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path10:before {
  content: '\ecf3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-shortcake .path11:before {
  content: '\ecf4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path1:before {
  content: '\ecf5';
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path2:before {
  content: '\ecf6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path3:before {
  content: '\ecf7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path4:before {
  content: '\ecf8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path5:before {
  content: '\ecf9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path6:before {
  content: '\ecfa';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path7:before {
  content: '\ecfb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path8:before {
  content: '\ecfc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path9:before {
  content: '\ecfd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path10:before {
  content: '\ecfe';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path11:before {
  content: '\ecff';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-cupcake-color .path12:before {
  content: '\ed00';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-cupcake-color .path13:before {
  content: '\ed01';
  margin-left: -1em;
  color: rgb(147, 211, 245);
}
.flaticon-cupcake-color .path14:before {
  content: '\ed02';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-cupcake-color .path15:before {
  content: '\ed03';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-cupcake-color .path16:before {
  content: '\ed04';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-cupcake-color .path17:before {
  content: '\ed05';
  margin-left: -1em;
  color: rgb(243, 164, 191);
}
.flaticon-cupcake-color .path18:before {
  content: '\ed06';
  margin-left: -1em;
  color: rgb(97, 177, 227);
}
.flaticon-cupcake-color .path19:before {
  content: '\ed07';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-cupcake-color .path20:before {
  content: '\ed08';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-cupcake-color .path21:before {
  content: '\ed09';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-cupcake-color .path22:before {
  content: '\ed0a';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-cupcake-color .path23:before {
  content: '\ed0b';
  margin-left: -1em;
  color: rgb(137, 103, 170);
}
.flaticon-cupcake-color .path24:before {
  content: '\ed0c';
  margin-left: -1em;
  color: rgb(97, 177, 227);
}
.flaticon-cupcake-color .path25:before {
  content: '\ed0d';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-cupcake-color .path26:before {
  content: '\ed0e';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-cupcake-color .path27:before {
  content: '\ed0f';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-cupcake-color .path28:before {
  content: '\ed10';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path29:before {
  content: '\ed11';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path30:before {
  content: '\ed12';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path31:before {
  content: '\ed13';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path32:before {
  content: '\ed14';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path33:before {
  content: '\ed15';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path34:before {
  content: '\ed16';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path35:before {
  content: '\ed17';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path36:before {
  content: '\ed18';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cupcake-color .path37:before {
  content: '\ed19';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pie .path1:before {
  content: '\ed1a';
  color: rgb(255, 255, 255);
}
.flaticon-pie .path2:before {
  content: '\ed1b';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-pie .path3:before {
  content: '\ed1c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pie .path4:before {
  content: '\ed1d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pie .path5:before {
  content: '\ed1e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pie .path6:before {
  content: '\ed1f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-pie .path7:before {
  content: '\ed20';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path1:before {
  content: '\ed21';
  color: rgb(165, 121, 56);
}
.flaticon-chocolate_bar .path2:before {
  content: '\ed22';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-chocolate_bar .path3:before {
  content: '\ed23';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path4:before {
  content: '\ed24';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path5:before {
  content: '\ed25';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path6:before {
  content: '\ed26';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path7:before {
  content: '\ed27';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chocolate_bar .path8:before {
  content: '\ed28';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-candy .path1:before {
  content: '\ed29';
  color: rgb(177, 204, 52);
}
.flaticon-candy .path2:before {
  content: '\ed2a';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-candy .path3:before {
  content: '\ed2b';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-candy .path4:before {
  content: '\ed2c';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-candy .path5:before {
  content: '\ed2d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-candy .path6:before {
  content: '\ed2e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-candy .path7:before {
  content: '\ed2f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-candy .path8:before {
  content: '\ed30';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path1:before {
  content: '\ed31';
  color: rgb(234, 90, 71);
}
.flaticon-lollipop .path2:before {
  content: '\ed32';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path3:before {
  content: '\ed33';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path4:before {
  content: '\ed34';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path5:before {
  content: '\ed35';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path6:before {
  content: '\ed36';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path7:before {
  content: '\ed37';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path8:before {
  content: '\ed38';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path9:before {
  content: '\ed39';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path10:before {
  content: '\ed3a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path11:before {
  content: '\ed3b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path12:before {
  content: '\ed3c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path13:before {
  content: '\ed3d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-lollipop .path14:before {
  content: '\ed3e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path1:before {
  content: '\ed3f';
  color: rgb(234, 90, 71);
}
.flaticon-custard .path2:before {
  content: '\ed40';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-custard .path3:before {
  content: '\ed41';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-custard .path4:before {
  content: '\ed42';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-custard .path5:before {
  content: '\ed43';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-custard .path6:before {
  content: '\ed44';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-custard .path7:before {
  content: '\ed45';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path8:before {
  content: '\ed46';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path9:before {
  content: '\ed47';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path10:before {
  content: '\ed48';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path11:before {
  content: '\ed49';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path12:before {
  content: '\ed4a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-custard .path13:before {
  content: '\ed4b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path1:before {
  content: '\ed4c';
  color: rgb(245, 171, 65);
}
.flaticon-honey_pot .path2:before {
  content: '\ed4d';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-honey_pot .path3:before {
  content: '\ed4e';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-honey_pot .path4:before {
  content: '\ed4f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path5:before {
  content: '\ed50';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path6:before {
  content: '\ed51';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path7:before {
  content: '\ed52';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path8:before {
  content: '\ed53';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path9:before {
  content: '\ed54';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path10:before {
  content: '\ed55';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path11:before {
  content: '\ed56';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-honey_pot .path12:before {
  content: '\ed57';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baby_bottle .path1:before {
  content: '\ed58';
  color: rgb(252, 234, 45);
}
.flaticon-baby_bottle .path2:before {
  content: '\ed59';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-baby_bottle .path3:before {
  content: '\ed5a';
  margin-left: -1em;
  color: rgb(147, 211, 245);
}
.flaticon-baby_bottle .path4:before {
  content: '\ed5b';
  margin-left: -1em;
  color: rgb(97, 177, 227);
}
.flaticon-baby_bottle .path5:before {
  content: '\ed5c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baby_bottle .path6:before {
  content: '\ed5d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baby_bottle .path7:before {
  content: '\ed5e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baby_bottle .path8:before {
  content: '\ed5f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-baby_bottle .path9:before {
  content: '\ed60';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-glass_of_milk .path1:before {
  content: '\ed61';
  color: rgb(255, 255, 255);
}
.flaticon-glass_of_milk .path2:before {
  content: '\ed62';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-glass_of_milk .path3:before {
  content: '\ed63';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-glass_of_milk .path4:before {
  content: '\ed64';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_beverage .path1:before {
  content: '\ed65';
  color: rgb(255, 255, 255);
}
.flaticon-hot_beverage .path2:before {
  content: '\ed66';
  margin-left: -1em;
  color: rgb(106, 70, 47);
}
.flaticon-hot_beverage .path3:before {
  content: '\ed67';
  margin-left: -1em;
  color: rgb(165, 121, 56);
}
.flaticon-hot_beverage .path4:before {
  content: '\ed68';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-hot_beverage .path5:before {
  content: '\ed69';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-teacup_without_handle .path1:before {
  content: '\ed6a';
  color: rgb(255, 255, 255);
}
.flaticon-teacup_without_handle .path2:before {
  content: '\ed6b';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-teacup_without_handle .path3:before {
  content: '\ed6c';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-teacup_without_handle .path4:before {
  content: '\ed6d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-teacup_without_handle .path5:before {
  content: '\ed6e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-teacup_without_handle .path6:before {
  content: '\ed6f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path1:before {
  content: '\ed70';
  color: rgb(255, 255, 255);
}
.flaticon-sake .path2:before {
  content: '\ed71';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path3:before {
  content: '\ed72';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path4:before {
  content: '\ed73';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path5:before {
  content: '\ed74';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path6:before {
  content: '\ed75';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path7:before {
  content: '\ed76';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-sake .path8:before {
  content: '\ed77';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path9:before {
  content: '\ed78';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path10:before {
  content: '\ed79';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path11:before {
  content: '\ed7a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path12:before {
  content: '\ed7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-sake .path13:before {
  content: '\ed7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bottle_with_popping_cork .path1:before {
  content: '\ed7d';
  color: rgb(177, 204, 52);
}
.flaticon-bottle_with_popping_cork .path2:before {
  content: '\ed7e';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-bottle_with_popping_cork .path3:before {
  content: '\ed7f';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-bottle_with_popping_cork .path4:before {
  content: '\ed80';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-bottle_with_popping_cork .path5:before {
  content: '\ed81';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-bottle_with_popping_cork .path6:before {
  content: '\ed82';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-bottle_with_popping_cork .path7:before {
  content: '\ed83';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-bottle_with_popping_cork .path8:before {
  content: '\ed84';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-bottle_with_popping_cork .path9:before {
  content: '\ed85';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-bottle_with_popping_cork .path10:before {
  content: '\ed86';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-bottle_with_popping_cork .path11:before {
  content: '\ed87';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-bottle_with_popping_cork .path12:before {
  content: '\ed88';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-bottle_with_popping_cork .path13:before {
  content: '\ed89';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bottle_with_popping_cork .path14:before {
  content: '\ed8a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bottle_with_popping_cork .path15:before {
  content: '\ed8b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-bottle_with_popping_cork .path16:before {
  content: '\ed8c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path1:before {
  content: '\ed8d';
  color: rgb(234, 90, 71);
}
.flaticon-wine_glass .path2:before {
  content: '\ed8e';
  margin-left: -1em;
  color: rgb(234, 90, 71);
}
.flaticon-wine_glass .path3:before {
  content: '\ed8f';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-wine_glass .path4:before {
  content: '\ed90';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path5:before {
  content: '\ed91';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path6:before {
  content: '\ed92';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path7:before {
  content: '\ed93';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path8:before {
  content: '\ed94';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-wine_glass .path9:before {
  content: '\ed95';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path1:before {
  content: '\ed96';
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path2:before {
  content: '\ed97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path3:before {
  content: '\ed98';
  margin-left: -1em;
  color: rgb(177, 204, 52);
}
.flaticon-cocktail_glass .path4:before {
  content: '\ed99';
  margin-left: -1em;
  color: rgb(91, 158, 50);
}
.flaticon-cocktail_glass .path5:before {
  content: '\ed9a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path6:before {
  content: '\ed9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path7:before {
  content: '\ed9c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path8:before {
  content: '\ed9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cocktail_glass .path9:before {
  content: '\ed9e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path1:before {
  content: '\ed9f';
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path2:before {
  content: '\eda0';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-tropical_drink .path3:before {
  content: '\eda1';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-tropical_drink .path4:before {
  content: '\eda2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path5:before {
  content: '\eda3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path6:before {
  content: '\eda4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path7:before {
  content: '\eda5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path8:before {
  content: '\eda6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tropical_drink .path9:before {
  content: '\eda7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beer_mug .path1:before {
  content: '\eda8';
  color: rgb(252, 234, 45);
}
.flaticon-beer_mug .path2:before {
  content: '\eda9';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-beer_mug .path3:before {
  content: '\edaa';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-beer_mug .path4:before {
  content: '\edab';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-beer_mug .path5:before {
  content: '\edac';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beer_mug .path6:before {
  content: '\edad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beer_mug .path7:before {
  content: '\edae';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path1:before {
  content: '\edaf';
  color: rgb(252, 234, 45);
}
.flaticon-clinking_beer_mugs .path2:before {
  content: '\edb0';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-clinking_beer_mugs .path3:before {
  content: '\edb1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-clinking_beer_mugs .path4:before {
  content: '\edb2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-clinking_beer_mugs .path5:before {
  content: '\edb3';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-clinking_beer_mugs .path6:before {
  content: '\edb4';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-clinking_beer_mugs .path7:before {
  content: '\edb5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-clinking_beer_mugs .path8:before {
  content: '\edb6';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-clinking_beer_mugs .path9:before {
  content: '\edb7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path10:before {
  content: '\edb8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path11:before {
  content: '\edb9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path12:before {
  content: '\edba';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path13:before {
  content: '\edbb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path14:before {
  content: '\edbc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_beer_mugs .path15:before {
  content: '\edbd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path1:before {
  content: '\edbe';
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path2:before {
  content: '\edbf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path3:before {
  content: '\edc0';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-clinking_glasses .path4:before {
  content: '\edc1';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-clinking_glasses .path5:before {
  content: '\edc2';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-clinking_glasses .path6:before {
  content: '\edc3';
  margin-left: -1em;
  color: rgb(240, 179, 27);
}
.flaticon-clinking_glasses .path7:before {
  content: '\edc4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path8:before {
  content: '\edc5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path9:before {
  content: '\edc6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path10:before {
  content: '\edc7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path11:before {
  content: '\edc8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-clinking_glasses .path12:before {
  content: '\edc9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tumbler_glass .path1:before {
  content: '\edca';
  color: rgb(0, 0, 0);
}
.flaticon-tumbler_glass .path2:before {
  content: '\edcb';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-tumbler_glass .path3:before {
  content: '\edcc';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-tumbler_glass .path4:before {
  content: '\edcd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-tumbler_glass .path5:before {
  content: '\edce';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-tumbler_glass .path6:before {
  content: '\edcf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tumbler_glass .path7:before {
  content: '\edd0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-tumbler_glass .path8:before {
  content: '\edd1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cup_with_straw .path1:before {
  content: '\edd2';
  color: rgb(210, 47, 39);
}
.flaticon-cup_with_straw .path2:before {
  content: '\edd3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cup_with_straw .path3:before {
  content: '\edd4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cup_with_straw .path4:before {
  content: '\edd5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-cup_with_straw .path5:before {
  content: '\edd6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path1:before {
  content: '\edd7';
  color: rgb(245, 171, 65);
}
.flaticon-beverage_box .path2:before {
  content: '\edd8';
  margin-left: -1em;
  color: rgb(252, 234, 45);
}
.flaticon-beverage_box .path3:before {
  content: '\edd9';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-beverage_box .path4:before {
  content: '\edda';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-beverage_box .path5:before {
  content: '\eddb';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-beverage_box .path6:before {
  content: '\eddc';
  margin-left: -1em;
  color: rgb(245, 171, 65);
}
.flaticon-beverage_box .path7:before {
  content: '\eddd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path8:before {
  content: '\edde';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path9:before {
  content: '\eddf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path10:before {
  content: '\ede0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path11:before {
  content: '\ede1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-beverage_box .path12:before {
  content: '\ede2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mate .path1:before {
  content: '\ede3';
  color: rgb(245, 171, 65);
}
.flaticon-mate .path2:before {
  content: '\ede4';
  margin-left: -1em;
  color: rgb(226, 112, 34);
}
.flaticon-mate .path3:before {
  content: '\ede5';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-mate .path4:before {
  content: '\ede6';
  margin-left: -1em;
  color: rgb(156, 155, 154);
}
.flaticon-mate .path5:before {
  content: '\ede7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mate .path6:before {
  content: '\ede8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-mate .path7:before {
  content: '\ede9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice .path1:before {
  content: '\edea';
  color: rgb(147, 211, 245);
}
.flaticon-ice .path2:before {
  content: '\edeb';
  margin-left: -1em;
  color: rgb(97, 177, 227);
}
.flaticon-ice .path3:before {
  content: '\edec';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.flaticon-ice .path4:before {
  content: '\eded';
  margin-left: -1em;
  color: rgb(147, 211, 245);
}
.flaticon-ice .path5:before {
  content: '\edee';
  margin-left: -1em;
  color: rgb(147, 211, 245);
}
.flaticon-ice .path6:before {
  content: '\edef';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-ice .path7:before {
  content: '\edf0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chopsticks .path1:before {
  content: '\edf1';
  color: rgb(210, 47, 39);
}
.flaticon-chopsticks .path2:before {
  content: '\edf2';
  margin-left: -1em;
  color: rgb(210, 47, 39);
}
.flaticon-chopsticks .path3:before {
  content: '\edf3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-chopsticks .path4:before {
  content: '\edf4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife_with_plate .path1:before {
  content: '\edf5';
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife_with_plate .path2:before {
  content: '\edf6';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife_with_plate .path3:before {
  content: '\edf7';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife_with_plate .path4:before {
  content: '\edf8';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife_with_plate .path5:before {
  content: '\edf9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife_with_plate .path6:before {
  content: '\edfa';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife_with_plate .path7:before {
  content: '\edfb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife_with_plate .path8:before {
  content: '\edfc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife_with_plate .path9:before {
  content: '\edfd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife .path1:before {
  content: '\edfe';
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife .path2:before {
  content: '\edff';
  margin-left: -1em;
  color: rgb(208, 207, 206);
}
.flaticon-fork_and_knife .path3:before {
  content: '\ee00';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife .path4:before {
  content: '\ee01';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-fork_and_knife .path5:before {
  content: '\ee02';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-spoon .path1:before {
  content: '\ee03';
  color: rgb(208, 207, 206);
}
.flaticon-spoon .path2:before {
  content: '\ee04';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.flaticon-money:before {
  content: '\ee05';
}
.flaticon-food-23:before {
  content: '\ee06';
}
.flaticon-food-17:before {
  content: '\ee07';
}
.flaticon-food:before {
  content: '\ee08';
}
.flaticon-piece-of-cake:before {
  content: '\ee09';
}
.flaticon-kebab:before {
  content: '\ee0a';
}
.flaticon-cup:before {
  content: '\ee0b';
}
.flaticon-ball_basketball:before {
  content: '\ee0c';
}
.flaticon-ball_football:before {
  content: '\ee0d';
}
.flaticon-corkscrew:before {
  content: '\ee0e';
}
.flaticon-cutlery:before {
  content: '\ee0f';
}
.flaticon-farfalle:before {
  content: '\ee10';
}
.flaticon-food_and_drink:before {
  content: '\ee11';
}
.flaticon-fusilli:before {
  content: '\ee12';
}
.flaticon-half_of_pizza:before {
  content: '\ee13';
}
.flaticon-ice_hockey:before {
  content: '\ee14';
}
.flaticon-milk:before {
  content: '\ee15';
}
.flaticon-milk_products:before {
  content: '\ee16';
}
.flaticon-pastry:before {
  content: '\ee17';
}
.flaticon-penne:before {
  content: '\ee18';
}
.flaticon-pizzabox:before {
  content: '\ee19';
}
.flaticon-pretzel:before {
  content: '\ee1a';
}
.flaticon-thumb_up:before {
  content: '\ee1b';
}
.flaticon-wine:before {
  content: '\ee1c';
}
.flaticon-credit-card:before {
  content: '\f101';
}
.flaticon-black-and-white-credit-cards:before {
  content: '\f102';
}
.flaticon-chevron-up:before {
  content: '\f103';
}
.flaticon-technology:before {
  content: '\f104';
}
.flaticon-transport-1:before {
  content: '\f105';
}
.flaticon-sign:before {
  content: '\f106';
}
.flaticon-food-30:before {
  content: '\f107';
}
.flaticon-fruit-2:before {
  content: '\f108';
}
.flaticon-food-29:before {
  content: '\f109';
}
.flaticon-food-291:before {
  content: '\f10a';
}
.flaticon-food-27:before {
  content: '\f10b';
}
.flaticon-food-26:before {
  content: '\f10c';
}
.flaticon-food-25:before {
  content: '\f10d';
}
.flaticon-drink-5:before {
  content: '\f10e';
}
.flaticon-cheese:before {
  content: '\f10f';
}
.flaticon-food-24:before {
  content: '\f110';
}
.flaticon-strawberry:before {
  content: '\f111';
}
.flaticon-drink-4:before {
  content: '\f112';
}
.flaticon-drink-3:before {
  content: '\f113';
}
.flaticon-cup-2:before {
  content: '\f114';
}
.flaticon-drink-2:before {
  content: '\f115';
}
.flaticon-drink-1:before {
  content: '\f117';
}
.flaticon-drink:before {
  content: '\f118';
}
.flaticon-fruit-1:before {
  content: '\f119';
}
.flaticon-food-22:before {
  content: '\f11a';
}
.flaticon-food-21:before {
  content: '\f11b';
}
.flaticon-food-20:before {
  content: '\f11c';
}
.flaticon-food-19:before {
  content: '\f11d';
}
.flaticon-transport:before {
  content: '\f11e';
}
.flaticon-edit:before {
  content: '\f11f';
}
.flaticon-hot-pepper-outline:before {
  content: '\f120';
}
.flaticon-smoking-barbecue:before {
  content: '\f121';
}
.flaticon-cardboard-box-with-chopsticks:before {
  content: '\f122';
}
.flaticon-meat-pie:before {
  content: '\f123';
}
.flaticon-mark:before {
  content: '\f124';
}
.flaticon-nature:before {
  content: '\f125';
}
.flaticon-sausage:before {
  content: '\f126';
}
.flaticon-hot-dog:before {
  content: '\f127';
}
.flaticon-restaurant:before {
  content: '\f128';
}
.flaticon-food-18:before {
  content: '\f129';
}
.flaticon-food-16:before {
  content: '\f12b';
}
.flaticon-fruit:before {
  content: '\f12c';
}
.flaticon-food-15:before {
  content: '\f12d';
}
.flaticon-food-14:before {
  content: '\f12e';
}
.flaticon-food-13:before {
  content: '\f12f';
}
.flaticon-food-12:before {
  content: '\f130';
}
.flaticon-clock:before {
  content: '\f132';
}
.flaticon-cup-1:before {
  content: '\f133';
}
.flaticon-whisky:before {
  content: '\f134';
}
.flaticon-food-11:before {
  content: '\f135';
}
.flaticon-soup:before {
  content: '\f136';
}
.flaticon-noodles-1:before {
  content: '\f137';
}
.flaticon-ramen:before {
  content: '\f138';
}
.flaticon-rice:before {
  content: '\f139';
}
.flaticon-food-10:before {
  content: '\f13a';
}
.flaticon-food-9:before {
  content: '\f13b';
}
.flaticon-spaghetti:before {
  content: '\f13c';
}
.flaticon-food-8:before {
  content: '\f13d';
}
.flaticon-food-7:before {
  content: '\f13e';
}
.flaticon-beer:before {
  content: '\f140';
}
.flaticon-delete:before {
  content: '\f141';
}
.flaticon-marshmallow:before {
  content: '\f142';
}
.flaticon-cake:before {
  content: '\f143';
}
.flaticon-split:before {
  content: '\f144';
}
.flaticon-ice-cream:before {
  content: '\f145';
}
.flaticon-cookie:before {
  content: '\f146';
}
.flaticon-doughnut:before {
  content: '\f147';
}
.flaticon-cupcake:before {
  content: '\f148';
}
.flaticon-food-6:before {
  content: '\f14a';
}
.flaticon-beans:before {
  content: '\f14b';
}
.flaticon-cart:before {
  content: '\f14c';
}
.flaticon-signs:before {
  content: '\f14d';
}
.flaticon-gps:before {
  content: '\f14e';
}
.flaticon-commerce-2:before {
  content: '\f14f';
}
.flaticon-noodles:before {
  content: '\f150';
}
.flaticon-fish:before {
  content: '\f151';
}
.flaticon-steak:before {
  content: '\f152';
}
.flaticon-food-5:before {
  content: '\f153';
}
.flaticon-food-4:before {
  content: '\f154';
}
.flaticon-food-3:before {
  content: '\f155';
}
.flaticon-fondue:before {
  content: '\f156';
}
.flaticon-skewer:before {
  content: '\f157';
}
.flaticon-pizza:before {
  content: '\f158';
}
.flaticon-food-2:before {
  content: '\f159';
}
.flaticon-shrimp:before {
  content: '\f15a';
}
.flaticon-food-1:before {
  content: '\f15b';
}
.flaticon-berry:before {
  content: '\f15d';
}
